import App from "next/app";
import React from "react";
import Head from "next/head";
import smoothscroll from "smoothscroll-polyfill"

import { Menu, Footer } from '../components'

import "../styles/global.scss";
import '../lib/date-cs-CZ'
import "aos/dist/aos.css"

class MyApp extends App {
  componentDidMount() {
    smoothscroll.polyfill()
  }

  render() {
    const { Component, pageProps, router } = this.props;
    const fixedMenu = router.route === '/[slug]' ? true : false
    const scrolledAfter = router.route === '/' ? true : false

    return (
      <>
        <Head>
          <title>Charger</title>
          <meta charSet="UTF-8"></meta>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="robots" content="index, follow"></meta>
          <meta name="description" content="Charger magazín"></meta>
          <meta property="og:site_name" content="T-Mobile Magazín"></meta>
          <link rel="icon" href="/static/images/favicon-32x32.png"  sizes="32x32" type="image/png" />
        </Head>
        <React.StrictMode>
          <Menu fixedMenu={fixedMenu} scrolledAfter={scrolledAfter} />
          <Component {...pageProps} />
          <Footer />
        </React.StrictMode>
      </>
    );
  }
}

export default MyApp;
