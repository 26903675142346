
export const dividerLine = (str, maxLineLength) => {
    const withoutSpace = str.split(" ");

    let resArr = []
    let numArr = 0;
    for (let i = 0; i < withoutSpace.length; i++) {
    if (resArr[numArr] === undefined) {
        resArr[numArr] = withoutSpace[i]
    } else if (resArr[numArr].length + withoutSpace[i].length < maxLineLength) {
        resArr[numArr] = `${resArr[numArr]} ${withoutSpace[i]}`;
    } else {
        resArr[numArr + 1] = withoutSpace[i];
        numArr++;
    }
}

    return resArr
}
