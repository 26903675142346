const LARGE_SIZE = 1000;
const MEDIUM_SIZE = 500;
const SMALL_SIZE = 245;

/*
*   width: width of screen
*   imgObj: obj with images
*   minWidth: set your min width for img
*/
export const getImg = ({width, imgObj, minWidth = 0}) => {
    if(!imgObj) return null;
    let imgUrl;
    //console.log('getImg width:'+width+' '+imgObj.url);
    //console.log(imgObj.formats);
    imgUrl = imgObj.url; // SET DEFAULT IMG IN ROOT OF 'imgObj'
    if (width < LARGE_SIZE || imgUrl === undefined) {
        //imgUrl = imgObj.formats?.large?.url || undefined;
        //console.log('large');
        //console.log(imgObj.formats?.large);
        imgUrl = imgObj.formats?.large || undefined;
    }
    if (width < MEDIUM_SIZE || imgUrl === undefined) {
        //imgUrl = imgObj.formats?.small?.url || undefined;
        //console.log('small');
        //console.log(imgObj.formats?.small);
        imgUrl = imgObj.formats?.small || undefined;
    }
    if ((width < SMALL_SIZE || imgUrl === undefined) && minWidth < SMALL_SIZE) {
        //imgUrl = imgObj.formats?.thumbnail?.url || "";
        //console.log('thumbnail');
        //console.log(imgObj.formats?.thumbnail);
        imgUrl = imgObj.formats?.thumbnail;
    }

    // Fix pictures src path, add backend public url if missing
    if (!imgUrl.toLowerCase().startsWith("http")) {
        imgUrl = process.env.NEXT_PUBLIC_DRUPAL_API_URL + imgUrl;
    }

    return imgUrl
}
