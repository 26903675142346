import React from "react"
import PropTypes from "prop-types"
import ReactMarkdownWithHtml from 'react-markdown/with-html'
import clsx from 'clsx'

import styles from "./DetailContent.module.scss"

export const DetailContent = ({ text, wpId }) => {
    const aosText = text.split("<p").join('<p data-aos="fade-up" ').split("<hr />").join('<hr data-aos="fade-up" />').split("](/").join(`](${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/`).split(`src="/`).join(`src="${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/`)

    return (
        <div className={styles.detailContentContainer}>
            <div className={styles.detailBlock}>
                {wpId ? 
                    <div className={styles.inner} dangerouslySetInnerHTML={{ __html: aosText }}></div> 
                : 
                    <div className={clsx(styles.inner, styles.markdown)} data-aos="fade-up">
                        <ReactMarkdownWithHtml source={aosText} allowDangerousHtml={true}  />
                        {/* <Markdown renderers={renderers} source={aosText.replaceAll('](/', `](${process.env.NEXT_PUBLIC_STRAPI_API_URL}/`)} allowDangerousHtml={true} /> */}
                    </div>}
            </div>
        </div>
    )
}

DetailContent.propTypes = {
    text: PropTypes.string,
    wpId: PropTypes.string
}
