import React, { useState } from "react"
import { useForm } from "react-hook-form";

import styles from "./CallbackForm.module.scss"

const SITE_KEY = process.env.RECAPTCHA_KEY;

// TODO RECAPTCHA, NOW I AM THING ITS NOT WORKING CORRECTLY
export const CallbackForm = () => {

    const { register, handleSubmit, reset, formState } = useForm();
    const { errors } = formState;

    const [ response, setResponse ] = useState(null);
    const [ loading, setLoading ] = useState(false)
    const [Drupalerrors, setDrupalerrors] = useState(null);


    function onSubmit(data) {
        setLoading(true);
        const response = fetch(`${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/webform_rest/submit`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                webform_id: "contact",
                name: data.name,
                email: data.email,
                message: data.message
            })
        })
        .then(res => res.json()).then(res => {
            if( res.sid ){
                setLoading(false);
                setResponse(res);
                setDrupalerrors(null);
            } else {
                setLoading(false);
                setDrupalerrors(res.message);
            }

        }).catch(err => {
            console.log("SUBMIT FAIL ERROR ",err);
        });
        return false;
    }

    return (
        <div className={styles.formContainer}>
            <h3 className={styles.title} data-aos="fade-up">Napište nám</h3>
            <form onSubmit={handleSubmit(onSubmit)} data-aos="fade-up">
                <div className={styles.innerContainer}>
                        {!response ? (
                            <>
                            <div className={styles.emailSendedContainer}>
                            {Drupalerrors}
                            </div>
                                <div className={styles.left}>
                                    <label htmlFor="name">
                                        <input name="name" placeholder="Jméno" ref={register({ required: true, minLength: 4, maxLength: 80 })} />
                                        {errors?.name &&<span className={styles.error}>Vyplňte jméno</span>}
                                    </label>
                                    <label htmlFor="email">
                                        <input name="email" placeholder="E-mail" ref={register({ required: true,  pattern: /^\S+@\S+$/i })} />
                                        {errors?.email?.type === "required" && <span className={styles.error}>Vyplňte e-mail</span>}
                                        {errors?.email?.type === "pattern" && <span className={styles.error}>E-mail je ve špatném tvaru</span>}
                                    </label>
                                </div>

                                <div className={styles.right}>
                                    <label htmlFor="message">
                                        <input name="message" placeholder="Zpráva" ref={register({ minLength: 5, required: true })} />
                                        {errors?.message && <span className={styles.error}>Vyplňte zprávu</span>}
                                    </label>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.emailSendedContainer}> Váš e-mail se odeslal, děkujeme.</div>
                            </>
                        )
                    }

                </div>

                {!response ? <button className={styles.submitButton} type="submit" disabled={loading}>
                    <span>Odeslat</span>
                    <div className={styles.line}></div>
                </button> : null }
            </form>
        </div>
    )

}
