import React from "react"

import styles from "./Footer.module.scss"

export const Footer = () => {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.footerInner}>
                <div className={styles.socialIconsContainer}>
                    <a href="https://www.facebook.com/TMobileCZ" target="_blank" title="Facebook"><img className={styles.icon} src='/static/images/social-icons/facebook.svg' width="30" height="30" alt="Facebook" /></a>
                    <a href="https://www.instagram.com/tmobile_cze/" target="_blank" title="Instagram"><img className={styles.icon} src='/static/images/social-icons/instagram.svg' width="30" height="30" alt="Instagram" /></a>
                    <a href="https://www.youtube.com/user/tmobilecz" target="_blank" title="Youtube"><img className={styles.icon} src='/static/images/social-icons/youtube.svg' width="30" height="30" alt="Youtube" /></a>
                    <a href="https://twitter.com/TMobile_CZE" target="_blank" title="Twitter"><img className={styles.icon} src='/static/images/social-icons/twitter.svg' width="30" height="30" alt="Twitter" /></a>
                </div>
                <img className={styles.logo} src='/static/images/footer-logo.png' width="145" height="106" alt="footer logo" />
                <p className={styles.copyright}>© 2020 T-Mobile Czech Republic a.s.</p>
                <p><a className="cookies-show" href="" style={{color: 'white'}}>Nastavení cookies</a></p>
            </div>
        </div>
    )
}