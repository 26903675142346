import React from "react"
import PropTypes from "prop-types"
import Link from "next/link";
import Markdown from 'react-markdown'

import { SearchForm } from "../../components/";

import { getImg } from '../../utils/getImg';
import styles from "./NotFoundHeader.module.scss"

const MOBILE_WIDTH = 767;

export const NotFoundHeader = ({ width }) => {
    //const { mobile_title, title, subtitle, perex, preview_picture, preview_picture_position, wpId, article_type } = articleData;

    //const img = getImg({ width, imgObj: preview_picture })
    const imgPosition = 'center'; 

    // const isBlueVersion = article_type === 'Blue';

    return (
        <div className={styles.detailHeaderContainer}>
            {/* <div className={styles.logo}>
                <Link href="/" as="/"><img className={styles.logoIcon} src={`/static/images/logo-small-${isBlueVersion ? 'black' : 'white'}.png`} width="131" height="91" alt='Detail logo' /></Link>
            </div> */}

            <div className={styles.right2}>
                <div className={styles.content}>
                <h1 dangerouslySetInnerHTML={{ __html: 'Obsah nebyl nalezen'}}></h1>
                <h2 dangerouslySetInnerHTML={{ __html: '- zkuste naše vyhledávání nebo <a href="/category/archives/archiv">Archiv</a>' }}></h2>
                </div>
                {/*
                <div className={styles.backgroundImage} style={{ backgroundImage: `url('/static/images/charger-logo-white.svg')`, backgroundPosition: imgPosition }}></div>
                */}

            </div>
            <div className={styles.right}>

                <SearchForm inMenu={false} />
            </div>

        </div>

    )
}

NotFoundHeader.propTypes = {
    //articleData: PropTypes.shape(),
    width: PropTypes.number
}