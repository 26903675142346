import React from "react"
import PropTypes from "prop-types"
import Link from "next/link";
import Markdown from 'react-markdown'

import { getImg } from '../../utils/getImg';
import styles from "./DetailHeader.module.scss"

const MOBILE_WIDTH = 767;

export const DetailHeader = ({ articleData, width }) => {
    const { mobile_title, title, subtitle, perex, preview_picture, preview_picture_position, wpId, article_type } = articleData;

    const img = getImg({ width, imgObj: preview_picture })
    const imgPosition = preview_picture_position || 'inherit';

    // const isBlueVersion = article_type === 'Blue';

    return (
        <div className={styles.detailHeaderContainer}>
            {/* <div className={styles.logo}>
                <Link href="/" as="/"><img className={styles.logoIcon} src={`/static/images/logo-small-${isBlueVersion ? 'black' : 'white'}.png`} width="131" height="91" alt='Detail logo' /></Link>
            </div> */}
            <div className={styles.left}>
                <div className={styles.backgroundImage} style={{ backgroundImage: `url(${img})`, backgroundPosition: imgPosition }}></div>
            </div>
            <div className={styles.right}>
                <h1 dangerouslySetInnerHTML={{ __html: width > MOBILE_WIDTH ? title : mobile_title}}></h1>
                {subtitle ? <h2 dangerouslySetInnerHTML={{ __html: subtitle }}></h2> : null}
                {perex ? (
                    wpId ? <p dangerouslySetInnerHTML={{ __html: perex }}></p> : <Markdown source={perex} />)
                : null}
            </div>
        </div>
    )
}

DetailHeader.propTypes = {
    articleData: PropTypes.shape(),
    width: PropTypes.number
}
