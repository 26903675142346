import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Link from "next/link";
import clsx from 'clsx'

import {NotFoundHeader} from '../../components';

import { getImg } from '../../utils/getImg';
import styles from "./FilterContent.module.scss"

const MOBILE_WIDTH = 767;

export const FilterContent = ({ catergoryName, articles, width, color = "black" }) => {
    const [ articleCount, setArticleCount ] = useState(10);
    const [ articleIncrease, setArticleIncrease ] = useState(10)

    useEffect(() => {
        const increaseNumber = width > MOBILE_WIDTH ? 16 : 10;
        setArticleCount(increaseNumber);
        setArticleIncrease(increaseNumber)
    }, [width])

    var logo = '/static/images/logo-small-'+color+'.png'

    if( articles.length == 0 || catergoryName == "")
        return (
        <div className={styles.filterContentContainer}>
            <div className={styles.logo}>
                <Link href="/" as="/"><img className={styles.logoIcon} src={logo} width="131" height="91" alt='Filter logo' /></Link>
            </div>

            <h3 dangerouslySetInnerHTML={{ __html: catergoryName }}></h3>
            <NotFoundHeader width={width} />
        </div>
        );

    return (
        <div className={styles.filterContentContainer}>
            <div className={styles.logo}>
                <Link href="/" as="/"><img className={styles.logoIcon} src={logo} width="131" height="91" alt='Filter logo' /></Link>
            </div>

            <h3 dangerouslySetInnerHTML={{ __html: catergoryName }}></h3>

            <div className={styles.boxesContainer}>
                {articles.map(({ title, preview_picture, slug }, index) => {
                    if (index > articleCount - 1) return null
                    const img = getImg({ width: width / 3, imgObj: preview_picture })

                    return (
                        <div className={styles.box} key={`box-${index}`}>
                            <div className={styles.backgroundImage} style={{ backgroundImage: `url(${img})`}}></div>
                            <div className={styles.content}>
                                <h2 className={styles.boxTitle} dangerouslySetInnerHTML={{ __html: title}}></h2>
                                <Link href={`/${slug}`} ><a title="Přečíst článek" href={`/${slug}`}>Přečíst článek</a></Link>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={clsx(styles.divider, articles.length <= articleCount && styles.dividerWithoutBtn)}>
                <div className={styles.loadMoreContainer}>
                   <div className={styles.loadMoreBtn} onClick={() => setArticleCount(articleCount + articleIncrease)}>Načíst další</div>
                </div>
            </div>
        </div>
    )
}

FilterContent.propTypes = {
    catergoryName: PropTypes.string,
    articles: PropTypes.array,
    width: PropTypes.number
}