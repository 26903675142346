import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import clsx from 'clsx';

import { Button } from "../common"
import { getImg } from '../../utils/getImg';

import styles from "./HeaderBoxes.module.scss"

export const HeaderBoxes = ({articles, width}) => {
    const [ headerArticles, setHeaderArticles ] = useState()
    const gridClass = `grid-${articles.length - 1}`;

    useEffect(() => {
        setHeaderArticles(articles)
    }, [articles])

    if (!headerArticles) return null

    return (
        <div className={clsx(styles.boxesContainer, styles[gridClass])}>
            {headerArticles.map(({ title, preview_picture, slug }, index) => {
                if (index === 0 || index > 4) return null
                const img = getImg({ width: width / 3, imgObj: preview_picture })
                
                return (
                    <div className={styles.box} key={`box-${index}`}>
                        <div className={styles.backgroundImage} style={{ backgroundImage: `url(${img})`}}></div>
                        <div className={styles.content}>
                            <h2 className={styles.boxTitle} dangerouslySetInnerHTML={{__html: title}}></h2>
                            <Button small url={`/${slug}`} style='pink' label="Přečíst článek" className={styles.button} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

HeaderBoxes.propTypes = {
    boxes: PropTypes.shape(),
    width: PropTypes.number
}