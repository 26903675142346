import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx";

import { getImg } from '../../utils/getImg';
import { dividerLine } from '../../utils/dividerLine';
import { Button } from "../common"

import styles from "./Header.module.scss"

const MOBILE_WIDTH = 767;
const SMALL_MOBILE_WIDTH = 400;

export const Header = ({ article, month, width }) => {
    const { mobile_title, title, subtitle, preview_picture, preview_picture_position, article_type } = article;

    const isBlueVersion = article_type === 'Blue';
    const img = getImg({ width, imgObj: preview_picture, minWidth: 1000 })
    const imgPosition = preview_picture_position || 'center'; 

    const headerTitle = (width > MOBILE_WIDTH ? title : mobile_title) || title;
    const headerLineLength = width > MOBILE_WIDTH ? 21 : (width < SMALL_MOBILE_WIDTH ? 19 : 25); // x-767 = 25; 767-400 = 21; 400-x = 19 
    const dividedHeaderTitle = dividerLine(headerTitle, headerLineLength);

    return (
        <div className={styles.headerContainer}>
            <div className={styles.backgroundImage} style={{ backgroundImage: `url(${img})`, backgroundPosition: imgPosition }}></div>

            <div className={styles.logoContainer}>
                <img className={styles.logoImage} width="75" height="180" src={`/static/images/charger-logo-${isBlueVersion ? 'blue' : 'white'}.svg`} alt='Header logo' />
                <span className={clsx(styles.logoText, isBlueVersion && styles.logoTextBlue)}>CHA<br/>RG<br />ER<br /><b>{month}</b></span>
            </div>

            <div className={clsx(styles.titleContainer, isBlueVersion && styles.blueVariant)}>
                <h1>
                    {dividedHeaderTitle.map((line, index) => (
                        <span key={index} dangerouslySetInnerHTML={{ __html: line}}></span>
                    ))}
                </h1>

                {subtitle ? <p dangerouslySetInnerHTML={{ __html: subtitle }}></p> : null}
                <Button style='pink' url={`${article.slug}`} label={"Přečíst článek"} className={styles.button} />
            </div>
        </div>
    )
}

Header.propTypes = {
  article: PropTypes.shape(),
  month: PropTypes.string,
  width: PropTypes.number
}
