import React from "react"
import PropTypes from "prop-types"
import Link from "next/link";

import styles from "./FilterContentArchive.module.scss"

export const FilterContentArchive = ({ catergoryName, articles }) => {
    return (
        <div className={styles.filterContentContainer}>
            <div className={styles.logo}>
                <Link href="/" as="/"><img className={styles.logoIcon} src='/static/images/logo-small-black.png' height="91" width="131" alt='Logo filter' /></Link>
            </div>

            <h3 dangerouslySetInnerHTML={{ __html: catergoryName }}></h3>

            <div className={styles.boxesContainer}>
                {articles.map(({ year, name, cover_picture, file  }, index) => {
                    const img = cover_picture?.url
                    const imgUrl = process.env.NEXT_PUBLIC_DRUPAL_API_URL + img;
                    const fileUrl = process.env.NEXT_PUBLIC_DRUPAL_API_URL + file.url

                    return (
                        <div className={styles.box} key={`box-${index}`}>
                            <div className={styles.backgroundImage} style={{ backgroundImage: `url(${imgUrl})`}}></div>
                            <div className={styles.content}>
                                <h2 className={styles.boxTitle} dangerouslySetInnerHTML={{ __html: year+' - '+name}}></h2>
                                <a title="Přečíst článek" href={fileUrl} target="_blank">Otevřít PDF</a>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={styles.divider}></div>
        </div>
    )
}

FilterContentArchive.propTypes = {
    catergoryName: PropTypes.string,
    articles: PropTypes.shape()
}