import React, { useState } from "react"
import PropTypes from "prop-types"

import styles from "./SearchForm.module.scss"

export const SearchForm = ({searchTerm, inMenu = false}) => {

    const [searchterm, setSearchTerm] = useState(searchTerm);

      const handleSubmit = (event) => {
        event.preventDefault();
        if (typeof window !== 'undefined' && searchterm != '') {
          window.location.href = window.location.origin+"/search/"+searchterm;
        }
      }

      //<div className={!inMenu ? styles.formContainer:null}>
    return (
        <div className={inMenu ? styles.formContainer+' '+styles.formContainerWhite : styles.formContainer}>
        <h3 className={styles.title} data-aos="fade-up">Vyhledávání</h3>
        <form onSubmit={handleSubmit}>
            <div className={styles.innerContainer}>
              <label htmlFor="search">
                <input
                  type="text" name="search" id="search"
                  value={searchterm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Co chci najít"
                />
              </label>
              <button className={styles.searchButton} type="submit">
                        <span>Hledat</span>
                        <div className={styles.line}></div>
                </button>
            </div>
        </form>
        </div>
      )
}

SearchForm.propTypes = {
    inMenu: PropTypes.bool,
    searchTerm: PropTypes.string
}




