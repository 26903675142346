import React, { useState, useEffect } from "react"
import clsx from 'clsx';
import PropTypes from "prop-types"

import { useDebouncedEffect } from "../../utils/useDebouncedEffect";
import { useWindowSize } from '../../utils/useWindowSize';
import { getAllYears } from '../../utils/getAllYears';

import styles from "./CalendarSelect.module.scss"

const START_YEAR = 2018;
const MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
const DATE = new Date();
const TABLET_WIDTH = 1024;

export const CalendarSelect = ({ _year, _month, _setMonth, _setYear, _padding }) => {
    const [ activeYear, setActiveYear ] = useState(_year);
    const [ activeMonth, setActiveMonth ] = useState(_month)
    const [ allYears ] = useState(getAllYears(START_YEAR).reverse())
    const { width } = useWindowSize();
    const [ desktopActive, setDesktopActive ] = useState(`${_year}-${_month}`);

    // CASE, IF YOU CLICK ON CURRENT YEAR AND THERE IS ONLY FEW ACTIVE MONTHS
    useEffect(() => {
        const disabled = activeYear === DATE.getFullYear() && parseInt(MONTHS[DATE.getMonth()]) < parseInt(activeMonth);
        if (disabled) {
            setActiveMonth(MONTHS[0])
        }
    }, [activeMonth])

    // INIT SCROLL DESKTOP - ONLY MONTH CHANGE
    useDebouncedEffect(() => {
        if (width > TABLET_WIDTH) {
            scrollAndChange({ activeMonth, activeYear })
        }
    }, 100, [activeMonth])

    // INIT SCROLL DEBOUNCE - MOBILE & YEAR CHANGE
    useDebouncedEffect(() => {
        if (width < TABLET_WIDTH) {
            scrollAndChange({ activeMonth, activeYear })
        } 
    }, 600, [activeMonth, activeYear])

    // SCROLL AND LOAD DATA FUNC
    const scrollAndChange = ({ activeMonth, activeYear }) => {
        _setMonth(activeMonth)
        _setYear(activeYear)
        window.scroll({ top: 0, behavior: 'smooth' });
    }

    // MOBILE ARROWS
    const clickMobileArrow = (direction) => {
        const monthIndex = MONTHS.indexOf(activeMonth);
        const yearIndex = allYears.indexOf(activeYear);
    
        if (direction === 'next') {
            if (MONTHS.length === monthIndex + 1) {
                setActiveYear(allYears[yearIndex-1])
                setActiveMonth(MONTHS[0])
            } else {
                setActiveMonth(MONTHS[monthIndex+1])
            }
        }

        if (direction === 'prev') {
            if (monthIndex - 1 < 0) {
                if (allYears.length > yearIndex+1) {
                    setActiveYear(allYears[yearIndex+1])
                    setActiveMonth(MONTHS[11])
                }
            } else {
                setActiveMonth(MONTHS[monthIndex-1])
            }
        }
    }

    // DESKTOP MONTH CLICK
    const onMonthClick = (month) => {
        setDesktopActive(`${activeYear}-${month}`)
        if (activeMonth === month) { // IF IS CLICKED MONTH ON DESKTOP, AND ITS SAME AS ACTIVE MONTH, THEN REINIT
            scrollAndChange({ activeMonth, activeYear })
        } else {
            setActiveMonth(month)
        }
    }

    return (
        <div className={styles.calendarContainer} >
            <h3 className={clsx(styles.title, _padding && styles.padding)} data-aos="fade-up">Předchozí čísla</h3>
            <div className={styles.yearsContainer} data-aos="fade-up">
                {allYears.map((year) => (
                    <div key={year} onClick={()=> setActiveYear(year)} className={clsx(styles.yearItem, activeYear === year && styles.active)}>{year}</div>
                ))}
            </div>

            <div className={styles.monthsContainer} data-aos="fade-up">
                {MONTHS.slice(0).reverse().map((month) => {
                    const disabled = activeYear === DATE.getFullYear() && parseInt(MONTHS[DATE.getMonth()]) < parseInt(month);
                    return (
                        <div key={month} className={
                            clsx(
                                styles.monthItemContainer, 
                                activeMonth === month && styles.active, // mobile active
                                desktopActive === `${activeYear}-${month}` && styles.activeDesktop, // desktop active
                                Date.today().toString("MM") === month && activeYear.toString() === Date.today().toString("yyyy") && styles.actual, // actual month
                                disabled && styles.disabled)
                        }> 
                            <div key="prev" onClick={()=> clickMobileArrow('next')} className={clsx(styles.arrow, styles.arrowPrev)}><img height="40" width="20" src='/static/images/arrow-left.svg' alt="prev button" /></div>
                            <div onClick={()=> !disabled && onMonthClick(month)} className={styles.monthItem} key={month}>{month}</div>
                            <div key="next" onClick={()=> clickMobileArrow('prev')} className={clsx(styles.arrow, styles.arrowNext)}><img height="40" width="20" src='/static/images/arrow-right.svg' alt="next button" /></div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

CalendarSelect.propTypes = {
    props: PropTypes.shape()
}

