import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { useRouter } from "next/router";

import styles from "./Button.module.scss"

/**
 * @param {object} props
 * @param {string?} props.className
 * @param {string?} props.label
 * @param {string} props.style
 * @param {string?} props.external
 * @param {string?} props.url
 * @param {boolean?} props.disabled
 * @param {boolean?} props.small
 */
const Button = (props) => {
    const { className, label, style, external, small, url, ...rest } = props
    const router = useRouter();

    if (external) {
        return (
            <a className={clsx(className, styles.btn, styles[`btn-${style}`], external && styles.external)} rel='noopener noreferrer' href={external} {...rest} target='_blank' title=''>
                {label}
            </a>
        )
    }

    return (
        <button
            className={clsx(className, styles.btn, styles[`btn-${style}`], small && styles.small)}
            onClick={() =>  router.push({ pathname: url })}
            {...rest}
        >
            {label}
        </button>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    style: PropTypes.oneOf(["pink"]),
    label: PropTypes.string,
    external: PropTypes.string,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
}

Button.defaultProps = {
    small: false,
    style: "transparent",
    label: "BUTTON_LABEL",
}

export default Button
