import axios from 'axios';

export function setAPIPath(params){
  //console.log('setAPIPath');
  //console.log(params);
  var path='';

  switch (params.type){
    case 'article':
    case 'list_of_slugs':
      path = 'node/article';
      break;
    case 'categories':
      path = 'taxonomy_term/categories';
      break;
    case 'downloads':
      path = 'node/archiv';
      break;
    case 'search':
      path = 'index/articles';
      break;
    case 'file':
      path = 'file/file'
      break;
    default:
      path = 'articles';
      break;
  }

  if(params.hasOwnProperty('query')){
    //console.log(params.query);
    const keys = Object.keys(params.query);
    var path_params = []
    keys.forEach(key => {
      //console.log(params.query[key]);
      //console.log(key);
      //console.log(params.query[key]);
      //debugger;
      switch(key){
        case 'slug':
          var p = `filter[slug][path]=field_slug`;
          if( typeof params.query[key]['value'] == 'object' ){
            var values = params.query[key]['value'];
            values.forEach(function(val,index){
              p = p+`&filter[slug][value][${index}]=${val}`;
            });
          } else {
            p = p+`&filter[slug][value]=${params.query[key]['value']}`;
          }
          if(params.query[key].hasOwnProperty('operator')){
            p = p+'&filter[slug][operator]='+params.query[key]['operator'];
          }
          path_params.push(p);
          break;

        case 'created_gte':
          var date_gte = Math.floor(new Date(params.query[key]['value']).getTime() / 1000);
          path_params.push(`filter[${key}][path]=created&filter[${key}][operator]=>&filter[${key}][value]=${date_gte}`);
          break;

        case 'created_lte':
          var date_lte = Math.floor(new Date(params.query[key]['value']).getTime() / 1000);
          path_params.push(`filter[${key}][path]=created&filter[${key}][operator]=<&filter[${key}][value]=${date_lte}`);
          break;

        case 'categories':
          path_params.push(`filter[categories][path]=field_categories.meta.drupal_internal__target_id&filter[categories][value]=${params.query[key]['value']}&fields[node--article]=field_slug,title,field_preview_picture`);
          break;

        case 'list_of_slugs':
          path_params.push(`fields[node--article]=field_slug&include`);
          break;

        case 'fulltext':
          if( params.query[key]['value'] != '' )
            path_params.push(`filter[fulltext]=${params.query[key]['value']}&include=field_preview_picture`);
          else
            path = '';
            break;
        case 'uuid':
            path += `/${params.query[key]['value']}`;
          break;
      }

    });
    path += '?'+path_params.join('&');
  }

  return path;
}

export function prepareResponse(type, response){
  var response_object = [];

  //console.log('prepareResponse '+type);
  //console.log(response.data);

  switch(type){
    case 'file':
      response_objectresponse_object.push({
        id: item.attributes.drupal_internal__fid,
        name: item.attributes.filename,
        formats: item.attributes.image_style_uri,
        mime: item.attributes.filemime,
        url:  item.attributes.uri.url
      });
      return response_object;

    case 'downloads':
      response.data.forEach(function(item, index){
        var attributes = item.attributes;
        response_object.push({
          id: item.id,
          name: item.attributes.title,
          year: item.attributes.field_archive_year,
          cover_picture_relationship: item.relationships.field_cover_picture?.data?.id,
          file_relationship: item.relationships.field_file?.data?.id
        });
        if( response.hasOwnProperty('included') ){
          var included = response.included;
          included.forEach(function(item){
            switch(item.type){
              case 'file--file':
                if( item.id == response_object[index].cover_picture_relationship){
                  response_object[index].cover_picture = {
                    id: item.attributes.drupal_internal__fid,
                    name: item.attributes.filename,
                    url:  item.attributes.uri.url
                  };
                }
                if( item.id == response_object[index].file_relationship){
                  response_object[index].file = {
                    id: item.attributes.drupal_internal__fid,
                    name: item.attributes.filename,
                    url:  item.attributes.uri.url
                  };
                }
                break;
            }

          });
        }
      });
      return response_object;

    case 'search':
    case 'article':
      if( response.data.length == 0){
        return response.data;
      }

      response.data.forEach(function(item, index){
        var attributes = item.attributes;
        response_object.push({
          id: item.id,
          title: attributes.title,
          slug: attributes.field_slug,
          subtitle: attributes.field_subtitle,
          mobile_title: attributes.field_mobile_title,
          text: attributes.body?.value,
          post_priority: attributes.field_post_priority,
          perex: attributes.field_perex,
          article_type: attributes.field_article_type,
          preview_picture_position: attributes.field_preview_picture_position,
          preview_picture_relationship: item.relationships.field_preview_picture?.data?.id
        });

        if( response.hasOwnProperty('included') ){
          var included = response.included;
          included.forEach(function(item){
            //console.log(item);
            //console.log(item.type);
            //debugger;
            switch(item.type){
              case 'file--file':
                if( item.id == response_object[index].preview_picture_relationship){
                  response_object[index].preview_picture = {
                    id: item.attributes.drupal_internal__fid,
                    formats: item.attributes.image_style_uri,
                    url:  item.attributes.uri?.url
                  };
                }
                break;
            }
          });
        }

      });
      return response_object;

    case 'categories':
      //console.log('prepareResponse');
      //console.log(response.data);

      response.data.forEach(function(item){
        response_object.push({
          id: item.attributes.drupal_internal__tid,
          name: item.attributes.name,
          slug: item.attributes.field_slug
        });
      });
      return response_object;

    case 'list_of_slugs':
      //console.log('prepareResponse');
      //console.log(response.data);
      if( response.data.length == 0){
        return response.data;
      }
      response.data.forEach(function(item){
        var attributes = item.attributes;
        response_object.push({
          'slug': attributes.field_slug
        });
      });
      return response_object;
  }
}

export function getBackendInternalURL(path = "") {
  return `${process.env.NEXT_INTERNAL_DRUPAL_API_URL}/jsonapi/${path}`;
}

export async function fetchInternalAPI(params){
  const path = setAPIPath(params);

  if(path){
    const requestUrl = getBackendInternalURL(path);
    const response = await axios.request({
      method: 'get',
      url: requestUrl,
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.api+json',
      }
    });

    try {
      return prepareResponse(params.type, response.data);
    } catch (e) {
      return [];
    }
  }
}

export function getBackendPublicURL(path = "") {
  return `${process.env.NEXT_PUBLIC_DRUPAL_API_URL}/jsonapi/${path}`;
}

export async function fetchPublicAPI(params){
  const path = setAPIPath(params);

  if(path){
    const requestUrl = getBackendPublicURL(path);
    const response = await axios.request({
      method: 'get',
      url: requestUrl,
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.api+json',
      }
    });

    try {
      return prepareResponse(params.type, response.data);
    } catch (e) {
      return [];
    }
  }
}
