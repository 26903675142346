import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { Button } from "../common"
import { getImg } from '../../utils/getImg';

import styles from "./RelatedArticles.module.scss"

export const RelatedArticles = ({ articles, width }) => {
    const [ articleData, setArticleData ] = useState()

    useEffect(() => {
        if(articles) {
            var articles_array = [];
            articles.forEach(function(item, index){
                articles_array.push(item);
            });
            setArticleData(articles_array);
        }
    }, [articles])
    
    if (!articleData) return null

    return (
        <>
            <h3 className={styles.title}>Související příspěvky</h3>
            <div className={styles.boxesContainer}>
                {articleData.map((item, index) => {
                    const { title } = item;
                    const img = getImg({ width: width / 3, imgObj: item.preview_picture })

                    return (
                        <div className={styles.box} key={`box-${index}`}>
                            <div className={styles.backgroundImage} style={{backgroundImage: `url(${img})`}}></div>
                            <div className={styles.content}>
                                <h2 className={styles.boxTitle} dangerouslySetInnerHTML={{__html: title}}></h2>
                                <Button small style='pink' url={`/${item.slug}`} label="Přečíst článek" className={styles.button} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

RelatedArticles.propTypes = {
    articles: PropTypes.array,
    width: PropTypes.number
}