import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { useRouter } from "next/router";
import Link from "next/link";

import { fetchPublicAPI } from '../../api'

import { SearchForm } from "../../components/";

import styles from "./Menu.module.scss"

export const Menu = ({fixedMenu = false, scrolledAfter = false}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuData, setMenuData] = useState();
    const [scrolledDown, setScrolledDown] = useState(false);

    const router = useRouter();

    useEffect(() => {
        (async () => {
            const _menuData = await fetchPublicAPI({type:`categories`});
            setMenuData([..._menuData, { name: 'Archiv', slug: 'archiv'}]) // manually added archive item to menu - because its pdf category, which isn't in drupal category
        })()
    }, [])

    useEffect(() => {
        window.onscroll = function() {
            if(window.pageYOffset >= 600) {
                !scrolledDown && setScrolledDown(true)
            } else {
                scrolledDown && setScrolledDown(false)
            }
        };

        return () => {
            window.onscroll = null;
        }
    }, [scrolledDown])

    const onMenuIconClick = () => {
        setMenuOpen(!menuOpen)
    }

    const logoClick = () => {
        setMenuOpen(false)
        router.push({ pathname: `/` });
    }

    const closeMenuOnClick = () => {
        setTimeout(() => {
            setMenuOpen(false)
        }, 250)
    }

    if (!menuData) return null

    return (
        <>
            <div className={clsx(styles.fixedMenu, fixedMenu && styles.show, scrolledAfter && styles.scrolledAfter, menuOpen && styles.openFixed, scrolledDown && styles.scrolledDown)}>
                <div className={styles.logo}>
                    <Link href="/" as="/"><img className={styles.logoIcon} src={`/static/images/logo-small-white.png`} width="131" height="91" alt='Detail logo' /></Link>
                </div>
                <div className={clsx(styles.menuButton, menuOpen && styles.menuButtonFixed)} onClick={onMenuIconClick}>
                    <div className={clsx(styles.navIcon, menuOpen && styles.navIconOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>

            {!fixedMenu && <div className={clsx(styles.menuButton, menuOpen && styles.menuButtonFixed)} onClick={onMenuIconClick}>
                <div className={clsx(styles.navIcon, menuOpen && styles.navIconOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>}

            <div className={clsx(styles.menuContainer, menuOpen && styles.open)}>
                <div className={styles.logo} onClick={logoClick}>
                    <img className={styles.logoIcon} src='/static/images/logo-small-black.png' height="91" width="131" alt='' />
                </div>
                <div className={styles.inner}>
                    {menuData.map(({ name, slug }) => {
                        return <Link key={name} href={`/category/archives/${slug}`} as={`/category/archives/${slug}`}><a onClick={()=> closeMenuOnClick()} className={styles.menuItem}>{name}</a></Link>
                    })}
                </div>
                <SearchForm inMenu={true} />

            </div>
        </>
    )
}

