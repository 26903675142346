import React from "react"

export const Loading = () => {
    return (
        <div className="loader-overlay">
            <div className="rating-indicator">
                <div className="fill-indicator fill-indicator_1"></div>
                <div className="fill-indicator fill-indicator_2"></div>
                <div className="fill-indicator fill-indicator_3"></div>
            </div>
        </div>
    )
}